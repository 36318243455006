<template>
  <div>
    <content-template :menuName="$t('sidebar.signedUpRequest')" :table="true">
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                v-if="role_type='admin' && role_type != 'superadmin'"
                  class="r-menu-item s-center-flex"
                  @click.prevent="openVerifyDialog(true, props.row, 'accept')"
                >
                  {{ $t("general.accept") }}
                </div>
                <div
                   v-if="role_type='admin' && role_type != 'superadmin'"
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openVerifyDialog(true, props.row, 'reject')"
                >
                  {{ $t("general.reject") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="dialogDelete" :title="$t('general.warning')">
      <template #modalContent>
        <p>
          {{ $t("general.areYouSureTo") }} {{ verify_type }}
          {{ $t("general.thisUser") }}
        </p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onFinishVerif">
              {{ $t("general.continue") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      current_id: "",
      columns: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "feedbackForm.submittedBy",
          fieldName: "creator_name",
          isSortable: true,
          class: "",
        },
        {
          label: "general.title",
          fieldName: "title",
          isSortable: true,
          class: "",
        },
        {
          label: "general.location",
          fieldName: "location",
          isSortable: true,
          class: "",
        },
        {
          label: "general.startDate",
          fieldName: "start_date",
          isSortable: true,
          class: "",
        },
        {
          label: "general.endDate",
          fieldName: "end_date",
          type: "date",
          isSortable: true,
          class: "",
        },
        {
          label: "Status",
          fieldName: "response_status",
          isSortable: true,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      rows: "expertmatching/getExpertMatching",
      role_type: "auth/getRole",
      request: "expertmatching/getRequest",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },
  methods: {
    ...mapActions({
      setSignUpRequestData: "expertmatching/setSignUpRequestData",
      setModal: "setModal",
    }),

    async initData() {
      await this.getData();
    },

    async onFinishVerif() {
      const data = {
        status: this.current_state.status,
        id: this.current_state.id,
      };
      await this.onVerifExpert(data);
      this.setModal(false);
    },

    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setSignUpRequestData();
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },
  },
};
</script>
